import PortableText from '@sanity/block-content-to-react'
import React from 'react'
import validator from 'validator'
import AddressBlock from './contact-addresses'
import serializers from './serializers'

import forms from '../styles/forms.module.scss'
import theme from '../styles/theme.module.scss'
import styles from './contact-client-inquiries.module.scss';
import contactIntroStyles from './contact-intro.module.scss'

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { feedbackMsg: null, services: [], serviceslist: '', isValid: true }
    this.domRef = React.createRef()
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleUrlChange = e => {
    let isValid = validator.isURL(e.target.value)
    this.setState({ [e.target.name]: e.target.value, isValid })
  }

  handleSubmit = e => {
    e.preventDefault()

    if (!this.state.isValid) return false

    const form = e.target
    fetch('https://hooks.zapier.com/hooks/catch/615425/buvjrfq', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => {
        this.setState({
          feedbackMsg: this.props.formSuccess,
        })
        this.domRef.current.reset()
      })
      .catch(error => {
        this.setState({
          feedbackMsg: this.props.formError,
        })
      })
  }

  render() {
    return (
      <div className={`${styles.root} ${forms.root} ${theme.gdcont} ${theme.gmcont} ${theme.mT6} ${theme.pB3}`}>
        <p className={`${theme.huge} ${theme.gds1} ${theme.gde9} ${theme.gmmain} ${theme.mB3}`}>
          {this.props.clientInquiriesHeadline}
        </p>
        <div
          className={`${theme.gdfull} ${theme.gmfull} ${theme.gdcont} ${theme.gmcont} ${theme.borderTop} ${theme.pT7}`}>
          <h1
            className={`${forms.title} ${theme.h2_founders} ${theme.gdmain} ${theme.gmmain} ${theme.mB7}`}>
            Client Inquiries
          </h1>

          <PortableText
            className={`${theme.portableText} ${theme.h3_tiempos} ${theme.gds1} ${theme.gde7} ${theme.mB1} ${theme.gmmain}`}
            renderContainerOnSingleChild={true}
            blocks={this.props._rawCopyclientInquiries}
            serializers={serializers}
          />

          <form
            ref={this.domRef}
            className={`${forms.form} ${theme.gds3} ${theme.gde10} ${theme.gmmain} ${theme.fdcont} ${theme.fmcont}`}
            name='clientInquiry'
            method='POST'
            data-netlify='true'
            data-netlify-honeypot='bot-field'
            // action='https://hooks.zapier.com/hooks/catch/615425/buvjrfq'
            onSubmit={this.handleSubmit}>
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type='hidden' name='form-name' value='clientInquiry' />
            <div hidden>
              <label>
                Don’t fill this out: <input name='bot-field' onChange={this.handleChange} />
              </label>
            </div>
            <div className={`${forms.formGroup} ${theme.mB38px} ${theme.fd4} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB8px}`} htmlFor='name'>
                Name*
              </label>
              <input
                className={`${forms.field}`}
                type='text'
                id='name'
                name='name'
                required
                onChange={this.handleChange}
              />
            </div>
            <div className={`${forms.formGroup} ${theme.mB38px} ${theme.fd4} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB8px}`} htmlFor='email'>
                Email *
              </label>
              <input
                className={`${forms.field}`}
                type='email'
                id='email'
                name='email'
                required
                onChange={this.handleChange}
              />
            </div>
            <div
              className={`${forms.formGroup} ${theme.mB12} ${theme.fd8} ${theme.fm4}`}>
              <label className={`${theme.h5_founders} ${theme.mB6px}`} htmlFor='about'>
                Tell us more *
              </label>
              <p className={`${theme.mB16px} ${styles.aboutSubtext}`}>
                We&apos;d love to hear more about your brand or organization, your needs, plus any details around budget and timeline.
              </p>
              <textarea
                className={`${forms.field} ${forms.textarea}`}
                type='textarea'
                id='about'
                name='about'
                required
                onChange={this.handleChange}
              />
            </div>
            <p>* indicates required fields</p>
            {this.state.feedbackMsg && (
              <div
                className={`${forms.message} ${theme.gds3} ${theme.gde10} ${theme.gmmain} ${theme.center}`}>
                <p>{this.state.feedbackMsg}</p>
              </div>
            )}
            <div
              className={`${forms.submitGroup} ${forms.formGroup} ${theme.mB170px} ${theme.mT38px} ${theme.fd8} ${theme.fdcont} ${theme.fmcont} ${theme.fdcenter}`}>
              <input
                className={`${forms.submit} ${theme.h3_founders} ${theme.fd4} ${theme.fm4}`}
                type='submit'
                value='Submit'
                tabIndex='0'
              />
            </div>
          </form>
        </div>
        <div
          className={`${contactIntroStyles.root} ${theme.gdfull} ${theme.gdcont} ${theme.gmfull} ${theme.gmcont} ${theme.pT3} ${theme.borderTop}`}>
          <AddressBlock {...this.props} />
        </div>
      </div>
    )
  }
}
