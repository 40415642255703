import React from "react";
import { graphql } from "gatsby";
import Container from "../../components/container";
import GraphQLErrorList from "../../components/graphql-error-list";
import Layout from "../../containers/layout";
import SEO from "../../components/seo";
import Form from "../../components/contact-client-inquiries";

export const query = graphql`
  query ClientInquiriesPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      ...SanitySiteSettingsFragment
    }
    clientInquiries: sanityContact {
      _rawAddressColumn1
      _rawAddressColumn2
      _rawAddressColumn3
      clientInquiriesHeadline
      _rawCopyclientInquiries(resolveReferences: { maxDepth: 10 })
      clientServices
      formSuccess
      formError
    }
  }
`;

const ClientInquiriesPage = (props) => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const clientInquiries = (data || {}).clientInquiries;

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  return (
    <Layout>
      <SEO title="Client Inquiries" description={site.description} keywords={site.keywords} />
      <Container>
        <Form {...clientInquiries} />
      </Container>
    </Layout>
  );
};

export default ClientInquiriesPage;
